import React from "react"

function PostBody(props) {
  const { description, content } = props
  return (
    <div className="row justify-content-md-center d-flex">
      <div className="col-lg-8">
        <div className="post__entry bg-f--primary">
          <p className="lead">{description}</p>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  )
}

export default PostBody
