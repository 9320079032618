import React from "react"

function Header(props) {
  const { title, date, cover, category } = props
  return (
    <>
      <div className="row justify-content-md-center d-flex mt-5">
        <div className="col-lg-8">
          <div className="col-md-12 mb-5">
            <span className="post__category subtitle justify-content-center d-flex">
              {category}
            </span>
            <h2 className="post__title justify-content-center d-flex text-center">
              {title}
            </h2>
            <span className="post__date  justify-content-center d-flex">
              {date}
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="post__img">
            <img src={cover} alt={title} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
