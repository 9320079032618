import React from "react"

function PostAuthor(props) {
  return (
    <div className="row justify-content-md-center d-flex">
      <div className="col-lg-8">
        <div className="post__author">
          <img
            src="/assets/img/blog/post-author-avatar.jpg"
            alt="Author profile photo"
            className="rounded-circle author__img"
          />
          <div className="author__text">
            <h5>Su Marco Mazzocchi</h5>
            <ul className="social-channels">
              <li>
                <a
                  href="https://medium.com/@marco.mazzocchi.pc"
                  target="_blank"
                >
                  <i className="lni-medium" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/marco-mazzocchi-pc/"
                  target="_blank"
                >
                  <i className="lni-linkedin-original" />
                </a>
              </li>
              <li>
                <a href="https://github.com/marco-mazzocchi/" target="_blank">
                  <i className="lni-github-original" />
                </a>
              </li>
            </ul>
            <p>
              Sono uno sviluppatore web specializzato in tecnologie frontend.
              <br />
              Mi piace condividere quello imparo, scopro e vivo perché possa
              essere di qualche utilità per le persone.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostAuthor
