import React from "react"
import { graphql } from "gatsby"
import { Disqus } from "gatsby-plugin-disqus"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHeader from "../components/post/PostHeader"
import PostBody from "../components/post/PostBody"
import PostFooter from "../components/post/PostFooter"
import PostAuthor from "../components/post/PostAuthor"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const description = post.frontmatter.description || post.excerpt

    const disqusConfig = {
      identifier: post.id,
      title: post.frontmatter.title,
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={description} />

        <div id="single-blog">
          <article className="single-post pb-md-10" id="blog-single">
            <div className="bg-f--secondary pt-5">
              <div className="container">
                <PostHeader
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  cover={post.frontmatter.cover}
                  category={post.frontmatter.category}
                />
                <PostBody description={description} content={post.html} />
                <PostFooter previous={previous} next={next} />
              </div>
            </div>

            <div className="bg-f--primary">
              <div className="container">
                <PostAuthor />
              </div>
              <div className="container">
                <div className="row justify-content-md-center d-flex">
                  <div className="col-8">
                    <h3>Commenti</h3>
                    <Disqus config={disqusConfig} />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      timeToRead
      html
      frontmatter {
        title
        date(formatString: "dddd, DD MMMM YYYY", locale: "it")
        description
        category
        cover
      }
    }
  }
`
