import React from "react"
import { Link } from "gatsby"

function PostFooter(props) {
  const { previous, next } = props
  return (
    <div className="single-post__footer">
      <div className="row justify-content-md-center d-flex">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-6 col-md-6 align-items-center justify-content-start d-flex">
              <div className="post__comment-count">
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </div>
            </div>
            <div className="col-6 col-md-6 align-items-center justify-content-end d-flex">
              <div className="post__comment-count">
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </div>
            </div>
            {/* <div className="mt-4 col-12 align-items-center justify-content-start d-flex">
              <div className="social-share">
                <span>Share:</span>
                <ul>
                  <li>
                    <a href="#!">
                      <i className="lni-facebook-filled" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="lni-twitter-filled" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="lni-behance-original" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="lni-pinterest" />
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostFooter
